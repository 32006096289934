<template>
  <div class="hisory-css">
    <div class="top">
      <div class="left">
        <img src="../../assets/evaluation/img7.png" />
        <span>企业自评自测</span>
      </div>
      <div class="right">
        <el-button @click="toAnswer" type="primary">继续答题</el-button>
        <el-button type="primary" @click="toAdd">新建自诊断</el-button>
      </div>
    </div>
    <div class="bottom" v-if="!showNull">
      <div class="item" v-for="(item,index) in historyList" :key="index">
        <div class="left">
          <div>
            <span>企业诊断结果</span>
            <span>查看报告</span>
          </div>
          <p>提交时间：{{item.saveTime | time}}</p>
        </div>
        <div class="right">
          <div>
            <p>{{item.evaluateSorce}}分</p>
            <p>熟练度</p>
          </div>
          <div>
            <p>{{item.level}}</p>
            <p>成熟度</p>
          </div>
          <div>
            <p>{{item.companyName}}</p>
            <p>提交企业</p>
          </div>
          <div>
            <img src="../../assets/evaluation/img8.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="null" v-if="showNull">
      <img src="../../assets/evaluation/null.png" />
      <span>空空如也，快去新建自诊断吧~</span>
    </div>
  </div>
</template>
  
  <script>
import { get, post } from "../../api/http";
export default {
  data() {
    return {
      headerColor: "#5A8BFF",
      showNull: false,
      historyList: [],
      compId: JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId,
      companyName: "",
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  mounted() {
    this.getHistory();
    this.getCompany();
  },
  methods: {
    getHistory() {
      get("/api/EvaluateMain?compId=" + this.compId).then((resp) => {
        if (resp.code == 200) {
          if (resp.count == 0) {
            this.showNull = true;
          } else {
            this.historyList = resp.data;
          }
        }
      });
    },
    toAdd() {
      this.$router.push({ path: "./newevaluationtest" });
    },
    getCompany() {
      this.$store.state.companyUserList.forEach((item) => {
        if (item.id == this.compId) {
          this.companyName = item.companyName;
        }
      });
    },
    handleCommand(command) {
      // 判断是否有未完成的题目
      get("/api/EvaluateMain/GetIsExistUndo?compId=" + this.compId).then(
        (res) => {
          if (res.data) {
            let queryParams = {
              id: 0,
              companyName: this.companyName,
              companyId: this.compId,
              saveUserName: JSON.parse(sessionStorage.getItem("userInfo"))
                .userName,
              saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id,
              saveTime: new Date().toISOString(),
              evaluateTag: 0,
              evaluateSorce: 0,
              remark: "",
              level: "",
              tenantId: JSON.parse(sessionStorage.getItem("userInfo")).tenantId,
              evaluateTypr: 0,
            };
            post("/api/EvaluateMain?type=" + command, queryParams).then(
              (res) => {
                if (res.code == 200) {
                  get(
                    "/api/EvaluateMain/GetCompanyIsUndo?compId=" + this.compId
                  ).then((res) => {
                    // this.$router.push("/answer?mainId=" + res.data.id);
                    this.$router.push({
                      path: "/answer",
                      query: {
                        params: JSON.stringify(res.data),
                      },
                    });
                  });
                }
              }
            );
          } else {
            this.$message({
              message: "您还有未完成的题目，请先完成！",
              type: "warning",
            });
            return;
          }
        }
      );
    },
    toAnswer() {
      if (this.historyList[0].evaluateTag == 0) {
        this.$router.push({
          path: "/AnswerSheet",
        //   query: {
        //     params: JSON.stringify(this.historyList[0]),
        //   },
        });
      } else {
        this.$message.warning("没有未完成的答题，请新建自诊断");
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.hisory-css {
  position: absolute;
  width: 100%;
  height: 100%;
}
.hisory-css .top {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.hisory-css .top .left {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.hisory-css .top .left img {
  width: 30px;
}
.hisory-css .top .left span {
  font-size: 15px;
  font-weight: bold;
  margin-left: 5px;
}
.hisory-css .top .right {
  margin-right: 50px;
}
// .hisory-css .top .right .el-button {
//   padding: 8px 0px !important;
//   font-size: 12px !important;
//   width: 95px !important;
// }
.hisory-css .bottom {
  margin-top: 20px;
}
.hisory-css .bottom .item {
  background-color: white;
  width: 92%;
  margin: 0 auto 10px auto;
  padding: 8px 15px 8px 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hisory-css .bottom .left {
  padding-top: 10px;
}
.hisory-css .bottom .left > div > span:nth-of-type(1) {
  font-size: 14px;
  font-weight: bold;
}
.hisory-css .bottom .left > div > span:nth-of-type(2) {
  background-color: #5a8bff;
  padding: 2px 10px;
  color: white;
  border-radius: 50px;
  margin-left: 10px;
}
.hisory-css .bottom .left p {
  color: rgb(150, 150, 150);
  margin-bottom: 0;
}
.hisory-css .bottom .right {
  display: flex;
  justify-content: space-between;
  width: 500px;
}
.hisory-css .bottom .right > div p:nth-of-type(1) {
  height: 15px;
}
.hisory-css .bottom .right > div {
  text-align: center;
}
.hisory-css .bottom .right > div:nth-of-type(1) > p:nth-of-type(1) {
  background-color: #ff9307;
  padding: 0 18px;
  color: white;
  border-radius: 50px;
}
.hisory-css .bottom .right > div:nth-of-type(2) > p:nth-of-type(1),
.hisory-css .bottom .right > div:nth-of-type(3) > p:nth-of-type(1) {
  font-weight: bold;
}
.hisory-css .bottom .right > div:nth-of-type(1) > p:nth-of-type(2),
.hisory-css .bottom .right > div:nth-of-type(2) > p:nth-of-type(2),
.hisory-css .bottom .right > div:nth-of-type(3) > p:nth-of-type(2) {
  color: #969696;
}
.hisory-css .bottom .right img {
  width: 3px;
  padding-top: 5px;
}
.null {
  text-align: center;
}
.null > img {
  width: 380px;
  display: block;
  margin: 100px auto 30px auto;
}
</style>